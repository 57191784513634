import { MembershipRole } from "../reclaim-api/team/Team";
import { EDITION_META } from "../reclaim-api/team/Team.consts";
import { ReclaimEdition, SubscriptionFrequencyStr } from "../reclaim-api/team/Team.types";
import { canChangeSettings, canInvite } from "../reclaim-api/team/Team.utils";
import { useTeamState } from "./atoms/useTeam";
import { useUsageData } from "./useUsageData";

export type UseSubscriptionLogicReturnType = {
  userCurrentEdition?: ReclaimEdition;
  userTerminalEdition?: ReclaimEdition;
  userUsageEdition?: ReclaimEdition;
  userIsAdmin: boolean;
  userIsOnTrial: boolean;
  userDaysLeftInTrial?: number;
  userTrialEnd?: Date;
  userIsOnCredits: boolean;
  subscriptionFrequency?: SubscriptionFrequencyStr;
  hasActiveSubscription: boolean;
  userCanInvite: boolean;
  userCanChangeSettings: boolean;
  userCanSetInviteLevels: boolean;
  pendingClawback: boolean;
  teamHasAutoRenew: boolean;
  teamPlanIsCancelled: { check: true; date: Date } | { check: false };
};

export const useSubscriptionLogic = (): UseSubscriptionLogicReturnType => {
  const { usageData } = useUsageData();
  const { team, userMembership } = useTeamState();

  /**
   * Member subscription
   */
  const userCurrentEdition = usageData?.currentEdition;
  const userTerminalEdition = usageData?.terminalEdition;
  const userUsageEdition = usageData?.usageEdition;

  const userIsAdmin = userMembership?.membershipRole === MembershipRole.Admin;

  let userIsOnTrial = !!userMembership?.trial;
  const userDaysLeftInTrial = userMembership?.daysLeftInTrial;
  const userTrialEnd = userMembership?.trialEnd;

  const userIsOnCredits = userCurrentEdition === "REWARD";

  /**
   * Team subscription
   */
  const subscriptionFrequency = team?.pricingSummary.subscriptionFrequency;
  const hasActiveSubscription = !!team?.hasSubId;
  const teamHasAutoRenew = !!team?.pricingSummary.autoRenew;
  const teamPlanIsCancelled =
    !!team &&
    !!userCurrentEdition &&
    !teamHasAutoRenew &&
    !EDITION_META[userCurrentEdition].nonPaid &&
    !!team.pricingSummary.subscriptionEnd
      ? ({ check: true, date: new Date(team.pricingSummary.subscriptionEnd) } as const)
      : ({ check: false } as const);

  /**
   * Legacy plan support
   */
  const userCanInvite = canInvite(userMembership?.membershipRole, team?.userInviteLevel);
  const userCanChangeSettings = canChangeSettings(userMembership?.membershipRole);
  const userCanSetInviteLevels =
    !!team && !team.pricingSummary.homogeneous && userIsAdmin && team.userInviteLevel !== "NONE";

  const pendingClawback = userMembership?.edition === "ASSISTANT" && usageData?.currentEdition !== "ASSISTANT";

  // TODO (RAI-7793)
  if (!team?.newPricing && userIsOnTrial) {
    userIsOnTrial = userIsOnTrial && userMembership?.editionAfterTrial !== "TEAM";
  }

  return {
    userCurrentEdition,
    userTerminalEdition,
    userUsageEdition,
    userIsAdmin,
    userIsOnTrial,
    userIsOnCredits,
    userDaysLeftInTrial,
    userTrialEnd,
    subscriptionFrequency,
    hasActiveSubscription,
    userCanInvite,
    userCanChangeSettings,
    userCanSetInviteLevels,
    pendingClawback,
    teamHasAutoRenew,
    teamPlanIsCancelled,
  };
};
